import PropTypes from "prop-types";
import { forwardRef, memo, useMemo } from "react";
import { twMerge } from "tailwind-merge";

const Box = forwardRef(({ className, as, ...props }, ref) => {
  const Component = as || "div";
  const classes = useMemo(()=>twMerge("box-border m-0", className),[className])
  return (
    <Component
      className={classes}
      ref={ref}
      {...props}
    />
  );
});

Box.displayName = "Box";

Box.propTypes = {
  className: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
};

export default memo(Box);
