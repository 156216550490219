import React, {
  createContext,
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import rosetta from "rosetta";

export const i18n = rosetta();

export const I18nContext = createContext();

// default language
i18n.locale("bs");

const I18n = React.memo(function I18n({ children, locale, lngDict }) {
  const dict = lngDict;
  const activeLocaleRef = useRef(locale || "bs");
  const [, setTick] = useState(0);
  const firstRender = useRef(true);

  const localeCallback = useCallback((l, dict) => {
    i18n.locale(l);
    activeLocaleRef.current = l;
    if (dict) {
      i18n.set(l, dict);
    }
    setTick((tick) => tick + 1);
  }, []);

  const t = useCallback((...args) => i18n.t(...args), []);

  const i18nWrapper = useMemo(
    () => ({
      activeLocale: activeLocaleRef.current,
      t,
      locale: localeCallback,
    }),
    [localeCallback, t],
  );

  // for initial SSR render
  if (locale && firstRender.current === true) {
    firstRender.current = false;
    i18nWrapper.locale(locale, dict);
  }

  // when locale is updated
  useEffect(() => {
    if (locale) {
      i18nWrapper.locale(locale, dict);
    }
  }, [locale, dict, i18nWrapper.locale, i18nWrapper]);

  return (
    <I18nContext.Provider value={i18nWrapper}>{children}</I18nContext.Provider>
  );
});

I18n.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.oneOf(["EN", "BS"]),
  lngDict: PropTypes.object,
};

export default I18n;
