import { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { cva } from "class-variance-authority";

import Box from "../Box";

const text = cva("block text-left text-primary-text font-body", {
  variants: {
    variant: {
      h1: "font-heading font-light text-3xl leading-relaxed tracking-normal",
      h2: "font-heading font-bold text-2xl leading-relaxed tracking-normal",
      h3: "font-heading font-normal text-xl leading-relaxed tracking-normal",
      h4: "font-heading font-medium text-lg leading-relaxed tracking-normal",
      h5: "font-heading font-medium text-base leading-relaxed tracking-normal",
      h6: "font-heading font-bold text-sm leading-relaxed tracking-normal",
      body: "font-body font-normal text-base leading-6 tracking-normal",
      small: "font-body font-normal text-sm leading-normal tracking-normal",
      superSmall:
        "font-body font-normal text-xs leading-normal tracking-normal",
    },
  },
});

const Text = forwardRef(({ variant = "body", className, ...props }, ref) => {
  const headings = ["h1", "h2", "h3", "h4", "h5", "h6"];
  // eslint-disable-next-line no-nested-ternary
  const as = props.as ? props.as : headings.includes(variant) ? variant : "div";
  return (
    <Box
      ref={ref}
      as={as}
      className={twMerge(text({ variant }), className)}
      {...props}
    />
  );
});

Text.propTypes = {
  // causes PropTypes error because we sometimes override text themeKey
  // variant: PropTypes.oneOf(variants),
  variant: PropTypes.string,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
    PropTypes.elementType,
  ]),
  className: PropTypes.string,
};

Text.displayName = "Text";

export default memo(Text);
